body {
  background-color: #503e9d !important;
}
/* start sm-side */
.head-page {
  width: 16px;
  height: 16px;
}
.sm-side {
  background-color: #503e9d;
  box-shadow: 1px -1px 7px 0px white;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
  box-shadow: none;
}
.sm-side img {
  width: 50px;
  height: 50px;
}
.sm-side .nav-link {
  position: relative !important;
  border-radius: 30px !important;
  color: white !important;
  font-size: 17px !important;
  font-weight: 700;
  width: 150px;
}
.navbar-toggler {
  border: 1px solid white;
  background-color: white;
  color: #fb6d3a;
}
.sm-side .nav-link {
  text-align: center;
}
.sm-side .nav-link:hover {
  color: #fb6d3a !important;
  background-color: white;
  border-radius: 30px !important;
}
.sm-side .active {
  border-radius: 30px !important;
  color: #fb6d3a !important;
  background-color: white !important;
}
/* end sm-side */

.home .video-player {
  padding-top: 70% !important;
  overflow: hidden !important;
}
.tab-pane {
  border-radius: 30px !important;
  padding: 50px 50px 30px;
  background-color: #ffffff !important;
  min-height: 90vh;
}
.pills-head {
  color: #2b3674 !important;
  font-weight: 700 !important;
  text-transform: capitalize;
  font-size: 28px;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 23px !important;
  line-height: 1;
  opacity: 0.75;
  color: #503e9d !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
}
.slick-slider {
  width: 98%;
  border: 0;
  border-radius: 25px !important;
}
.slick-slider img {
  width: 100%;
  height: 230px;
  border: 0;
  border-radius: 25px !important;
  margin: 0 auto -7px;
  padding: 0 10px;
}
/* end content */

/*start material */
.material .box {
  position: relative;
  border-radius: 30px !important;
  padding: 20px;
  height: 250px;
  overflow: hidden;
  transition: 0.3s;
}
.material .box:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 8px 2px #aca1a1d2;
}
.material .box img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.75;
}
.material .card-creator {
  font-size: 17px;
  z-index: 252;
  color: white !important;
}
.material .card-head span {
  font-size: 25px;
  z-index: 252;
  color: white !important;
}
.material .card-info {
  backdrop-filter: blur(3px);
  background-color: #5028bd87;
  border-radius: 15px;
}
.material .card-price {
  padding: 5px;
  border-radius: 8px;
  font-weight: 600;
  color: #42ee5e;
}
.material .view {
  background-color: #fb6d3a;
  color: white;
  font-weight: 700;
  text-align: center;
  transition: 0.3s;
  font-size: 15px;
}

.material .view:hover {
  background-color: white;
  color: #fb6d3a;
  letter-spacing: 1px;
}
/*end material */

/* start books */
.book-input {
  position: relative;
}
.search-icon {
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
#search1,
#search {
  background: #f6f6f5 !important;
  padding: 10px 0px 10px 40px;
  border-radius: 15px;
  color: #000000 !important;
  border: 1px solid transparent !important;
}
::placeholder {
  color: #b3b6b6;
}
.book {
  border-radius: 20px !important;
}
.book .card {
  border-radius: 20px !important;
  background: #f6f6f5 !important;
  border: 1px solid transparent !important;
  padding: 15px;
}
.book .card img {
  width: 120px;
  border-radius: 8px;
}
.card .info {
  width: 400px;
}
.card .info h3 {
  font-weight: 600;
}
.card .price {
  font-weight: 700;
  color: #fb6d3a;
}
.card .links {
  cursor: context-menu;
  border: none;
}
.card .links a,
.card .links button {
  width: 135px;
}
.card .links a {
  color: #2828ea !important;
  transition: 0.3s;
  border: 1px solid;
}
.card .links .empty {
  color: #2828ea !important;
  transition: 0.3s;
  border: 1px solid;
}
.card .links .download {
  color: #fb6d3a !important;
  border: 1px solid;
  transition: 0.3s;
}
.card a:hover {
  background-color: #2828ea !important;
  color: white !important;
}
.card .download:hover {
  background-color: #fb6d3a !important;
  color: white !important;
}
.sections .btn.active {
  background-color: #503e9d;
  font-weight: 500;
  color: white;
}
.back {
  background-color: white !important;
  font-weight: 500;
  color: #503e9d !important;
  font-weight: 600 !important;
  border: 1px solid #503e9d;
  font-size: 15px;
  transition: 0.3s;
}
.back:hover {
  background-color: #503e9d !important;
  color: white !important;
}
.card .info p {
  color: #888888;
  font-size: 15px;
}
.back button {
  transition: 0.3s !important;
}
/* end books */

/* satrt video */
.video video {
  width: 250px;
  height: 200px;
}
.video-player {
  border-radius: 10px !important;
}
.video-react-control-bar {
  background: rgba(0, 0, 0, 0.7);
}
.video-react .video-react-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  display: block;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%);
  margin: auto;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
}
/* end video */
.custom-swal {
  z-index: 1325659 !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  /* background-color: red; */
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 10px;
}
.codex {
  color: white;
  font-weight: bold;
  width: 100%;
  border-radius: 20px;
  transition: 0.3s;
  border: none;
}
.codex span {
  position: relative;
}
.codex span::after {
  position: absolute;
  content: "";
  background-color: #fb6d3a;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 1px;
  width: 100%;
  height: 2px;
  transition: 0.3s;
}
.codex span:hover::after {
  width: 0%;
}
.codex:hover {
  color: white;
}
.codex:focus {
  color: white !important;
  border: none !important;
  background-color: transparent !important;
}
