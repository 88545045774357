@media (min-width: 992px) and (max-width: 1200px) {
  .books {
    bottom: -16% !important;
  }
}
@media (max-width: 992px) {
  .books {
    bottom: -16% !important;
  }
  .books img {
    width: 340px;
  }
}
@media (max-width: 768px) {
  .heal {
    width: 170px;
    left: 5%;
  }
  .books img {
    width: 300px;
  }
  .all form {
    width: 370px;
  }
  .all form .head p {
    color: white;
    font-size: 11px;
  }
  .all form h2 {
    font-size: 11px !important;
    margin-top: 3px;
  }
  .lock {
    width: 180px !important;
  }
}
@media (max-width: 576px) {
  .heal {
    width: 130px;
    left: 5%;
  }
  .books {
    bottom: -12% !important;
  }
  .books img {
    width: 250px;
  }
  .all form {
    width: 300px;
  }
  .all form .head p {
    color: white;
    font-size: 10px;
  }
  .lock {
    width: 155px !important;
  }
  .all form h1 {
    font-size: 25px;
  }
  .info-head {
    font-size: 22px;
  }
  .all form p {
    font-size: 10px;
  }
  .all form label {
    font-size: 10px;
  }
  .all form .forgot {
    font-size: 10px;
  }
  input {
    height: 28px;
    font-size: 13px !important;
  }
  .information input {
    width: 90%;
  }
  .all form .btns button {
    font-size: 12px !important;
  }
  .eye {
    font-size: 13px;
  }
  .google {
    width: 15px;
  }
  .all form h6 {
    font-size: 10px;
  }
  .all form h2 {
    font-size: 8px !important;
    margin-top: 3px;
  }
}
@media (max-width: 367px) {
  .all form {
    width: 280px;
  }
}
