.details .element {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 50px !important;
  margin: auto;
}
.details h1 {
  color: white;
  font-weight: 600;
  position: relative;
  width: max-content;
}
.details h1::before {
  content: "";
  position: absolute;
  background-color: #fb6d3a;
  height: 3px;
  width: 10%;
  left: 0;
  bottom: 0px;
}
.details h1::after {
  content: "";
  position: absolute;
  background-color: #fb6d3a;
  height: 3px;
  width: 5%;
  left: 0;
  bottom: -5px;
}
.details img {
  width: 150px;
  height: 150px;
}
.details .span-head {
  text-transform: capitalize;
  margin-right: 5px;
  font-size: 20px;
  font-weight: bold;
}
.details .span-body {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 17px;
}
.details .pdf {
  overflow: hidden !important;
}
.details a {
  color: white !important;
  font-weight: 500;
  background-color: #503e9d !important;
  transition: 0.3s;
}
.details a:hover {
  letter-spacing: 1px;
}
.video-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.details .video-player {
  width: 100% !important;
  height: 350px !important;
  background-color: black;
  object-fit: cover;
}

.details .video-player {
  padding-top: 0 !important;
}
