.update {
  padding: 30px;
  position: relative;
}
.update .pills-head {
  color: white !important;
}
.update button {
  font-weight: 600 !important;
  transition: 0.3s !important;
}
.update .back {
  color: white !important;
  font-weight: 500;
  background-color: #503e9d !important;
  font-weight: 600 !important;
}
.update button:hover {
  letter-spacing: 1px;
}
.update img {
  width: 150px;
}
iframe {
  width: 300px;
  height: 300px;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.update .spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 8px solid #007bff;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.progress {
  width: 80%;
}
.progress-bar {
  background-color: #007bff;
  width: 100%;
  height: 19px;
}
